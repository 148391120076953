import { Activity } from './activity';
import { dtoToActivity } from './dtoToActivity';
import ActivityAPI from '../api/api';

// TODO[high]: this module should probably be made into a class service for testabilty

export const getUserActivities = async (
  userId: string
): Promise<Activity[]> => {
  const result = await ActivityAPI.fetchActivitiesByUserId(userId);
  const activities = result.map(dtoToActivity);

  return activities;
};

export const getActivitiesByThreadId = async (
  threadId: string
): Promise<Activity[]> => {
  const result = await ActivityAPI.fetchActivitiesByThreadId(threadId);

  const activities = result.map(dtoToActivity);

  return activities;
};

export default {
  getUserActivities,
  getActivitiesByThreadId,
};
