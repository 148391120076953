import { ActivityDTO } from './activity-api-types';

export const MOCK_ACTIVITIES: ActivityDTO[] = [
  {
    id: '3f3f26bb-f644-4374-b2f4-c20283e055be',
    actor: {
      name: 'User A',
      photo_url:
        'https://tapestry-uploads.s3.ap-southeast-2.amazonaws.com/production/files/1620691655-$2y$10$Wq5fa15XdxPCDPhBpvvEM.T6U0YVZncpvjzC5pFdP9u1obOG3NYvK.jpg',
      isSystem: false,
      isMe: true,
    },
    receiver: null,
    datetime: '2024-09-17T11:19:33Z',
    activity_type: 'tasks.create',
    activity_order: 1,
    description: 'User A created a task.',
    redirect_id: '00000000-1111-0000-0000-000000000000',
    redirect_type: 'tasks',
  },
  {
    id: '3f3f26bb-f644-4374-b2f4-c20283e055be',
    actor: {
      name: 'User B',
      photo_url: null,
      isSystem: false,
      isMe: false,
    },
    receiver: {
      name: 'User A',
      photo_url:
        'https://tapestry-uploads.s3.ap-southeast-2.amazonaws.com/production/files/1620691655-$2y$10$Wq5fa15XdxPCDPhBpvvEM.T6U0YVZncpvjzC5pFdP9u1obOG3NYvK.jpg',
      isSystem: false,
      isMe: true,
    },
    datetime: '2024-09-17T11:19:33Z',
    activity_type: 'tasks.assignee-update',
    activity_order: 1,
    description: 'User B assigned a task',
    redirect_id: '00000000-1111-0000-0000-000000000000',
    redirect_type: 'tasks',
  },
  {
    id: '3f3f26bb-f644-4374-b2f4-c20283e055be',
    actor: {
      name: 'User B',
      photo_url: null,
      isSystem: false,
      isMe: false,
    },
    receiver: null,
    datetime: '2024-09-17T11:19:33Z',
    activity_type: 'tasks.attachment-delete',
    activity_order: 1,
    description: 'User A deletes an attachments.',
    redirect_id: '00000000-1111-0000-0000-000000000000',
    redirect_type: 'tasks',
  },
  {
    id: '3f3f26bb-f644-4374-b2f4-c20283e055be',
    actor: {
      name: 'System',
      photo_url: null,
      isSystem: true,
      isMe: false,
    },
    datetime: '2024-09-17T11:19:33Z',
    activity_type: 'tasks.create',
    activity_order: 1,
    description: 'System creates a next recurring task',
    redirect_id: '00000000-1211-0000-0000-000000000000',
    redirect_type: 'tasks',
    receiver: null,
  },
];
