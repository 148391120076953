import { ActivityDTO } from './activity-api-types';
import { MOCK_ACTIVITIES } from './mock-activities';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchActivitiesByUserId = (userId: string): Promise<ActivityDTO[]> => {
  const result = MOCK_ACTIVITIES;

  return Promise.resolve(result);
};

const fetchActivitiesByThreadId = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  threadId: string
): Promise<ActivityDTO[]> => {
  const result = MOCK_ACTIVITIES;

  return Promise.resolve(result);
};

export default { fetchActivitiesByUserId, fetchActivitiesByThreadId };
