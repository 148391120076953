import {
  Activity,
  ActivityUser,
  useGetThreadActivities,
} from '@tapestry/shared/services/activity';
import { dateTime } from '@tapestry/shared/utils';
import { Avatar, Card } from '@tapestry/weave';
import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

const swapNameIfIsMe = (user: ActivityUser) => {
  if (user.isMe) {
    return 'You';
  }

  return user.name;
};

const ThreadActivityListItem: FC<{
  activity: Activity;
  isLoading: boolean;
}> = ({ activity: { actor, ...activity }, isLoading }) => {
  const displayTime = dateTime.fromNow(activity.datetime);

  return (
    <li className="flex items-start md:items-center">
      {isLoading ? (
        <Skeleton circle height={32} width={32} className="mt-1.5 md:mt-0" />
      ) : (
        <Avatar
          src={actor.photo_url}
          alt={actor.name}
          className="mt-1.5 md:mt-0"
        />
      )}

      <div className="ml-2 grow">
        <h3 className="truncate-2-lines leading-5 sm:leading-6">
          {isLoading ? (
            <Skeleton width={200} />
          ) : (
            <>
              <span className="font-bold">{swapNameIfIsMe(actor)}</span>{' '}
              {activity.action}{' '}
              <span className="text-pink font-bold">
                {activity.subject.displayType}
              </span>
              {activity.receiver ? (
                <> to {swapNameIfIsMe(activity.receiver)}</>
              ) : null}
            </>
          )}
        </h3>

        <time
          className="text-gray-text block text-sm md:hidden"
          dateTime={activity.datetime}
        >
          {isLoading ? <Skeleton width={100} /> : displayTime}
        </time>
      </div>

      <time
        className="text-gray-text ml-auto hidden text-sm md:block"
        dateTime={activity.datetime}
      >
        {isLoading ? <Skeleton width={100} /> : displayTime}
      </time>
    </li>
  );
};

export const ThreadActivityList: FC<{ threadId: string }> = ({ threadId }) => {
  const { activities, isLoading, error } = useGetThreadActivities(threadId);

  if (error) {
    return (
      <Card bgColor="bg-gray-lightest text-gray-text">
        We're sorry, an error has happened with our server whislt trying to load
        the activities
      </Card>
    );
  }

  return (
    <ul className="space-y-4">
      {activities.map((activity) => (
        <ThreadActivityListItem
          key={activity.id}
          activity={activity}
          isLoading={isLoading}
        />
      ))}
    </ul>
  );
};
