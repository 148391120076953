import { IsoString } from '@tapestry/types';
import { ActivityAction, ActivityDTO } from '../api/activity-api-types';
import { Activity } from './activity';

const constructRedirectURL = (redirectType: string, redirectId: string) => {
  // TODO[high]: need to url encode
  return `/${redirectType}/${redirectId}`;
};

const mapDTOActivityTypeToDisplayWords = (
  activityType: ActivityAction
): [string, string] => {
  switch (activityType) {
    case 'tasks.create':
      return ['created a', 'task'];
    case 'tasks.update':
      return ['updated a', 'task'];
    case 'tasks.delete':
      return ['deleted a', 'task'];
    case 'tasks.attachment-add':
      return ['added an attachment to', 'task'];
    case 'tasks.status-update':
      return ['updated the status of a', 'task'];
    case 'tasks.attachment-delete':
      return ['deleted an attachment from a', 'task'];
    case 'tasks.status-updateAssignment':
      return ['updated the assignment status of a', 'task'];
    case 'tasks.assignee-update':
      return ['updated an assignee on a', 'task'];
    default:
      return ['did an action to', 'something'];
  }
};

export const dtoToActivity = (ActivityDTO: ActivityDTO): Activity => {
  const redirectURL = constructRedirectURL(
    ActivityDTO.redirect_type,
    ActivityDTO.redirect_id
  );

  const [action, displayType] = mapDTOActivityTypeToDisplayWords(
    ActivityDTO.activity_type as ActivityAction
  );

  return {
    id: ActivityDTO.id,
    // who
    actor: {
      name: ActivityDTO.actor.name,
      photo_url: ActivityDTO.actor.photo_url || null,
      isSystem:
        ActivityDTO.actor?.isSystem || ActivityDTO.actor.name === 'System',
      isMe: false,
    },
    // did something - (i.e. assigned a task)
    action,
    // related to this
    subject: {
      title: 'my task title is beautiful', //TODO[high]: NEED TO REMOVE when backend is ready
      redirectURL,
      displayType,
    },
    // to someone else
    receiver: ActivityDTO.receiver || null,
    // metadata
    datetime: ActivityDTO.datetime as IsoString, //TODO[high]: how to check that its IsoString?
  };
};
