import { skipToken, useQuery } from '@tapestry/shared/client';
import ActivityService from './domain/activity-service';

export const useGetCurrentUserActivities = () => {
  let userId: string | null = null;

  if (typeof window !== 'undefined') {
    userId = window.localStorage.getItem('userId');
  }

  const query = useQuery({
    queryKey: ['current-user-activities', userId],
    queryFn: userId
      ? () => ActivityService.getUserActivities(userId)
      : skipToken,
  });

  return { ...query, userActivities: query.data || [] };
};

export const useGetThreadActivities = (threadId: string) => {
  const query = useQuery({
    queryKey: ['thread-activities', threadId],
    queryFn: threadId
      ? () => ActivityService.getActivitiesByThreadId(threadId)
      : skipToken,
  });

  return { ...query, activities: query.data || [] };
};
